import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import { useLightbox } from 'simple-react-lightbox';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';
import './moveInHomesDetails.css';
import Slider from "react-slick";
import data from '../../../staticData/staticData.js';
import { cardButton, cardButtonHover, htmlToText } from '../../CommonStyle/CommonStyle';
import { floorPlanDetails, moveInPlanDetailssdvdfverfd } from '../../../Api/Actions/FloorPlanDetails';
import { floorIcon } from '../../../Api/Actions/floorplan';
import { moveInReadyHomes } from '../../../Api/Actions/homePageApi';
import Fade from 'react-reveal/Fade';
import { bookButtonSettings } from '../../../Api/Actions/bookNow';
import { Helmet } from 'react-helmet';



function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className="owl-next la la-angle-right" onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav">
      <div className=" owl-prev la la-angle-left" onClick={onClick} style={{ zIndex: 1 }} />
    </div>
  );
}
//Light Gallery on icon click 
/* const Iconimage = props => {
    const { openLightbox } = useLightbox()
	
  return (
    
  )
} */

const GalleryBox = ({ imageBlog }, props) => {
  const { openLightbox } = useLightbox()
  return (

    <div className="dlab-media dlab-img-overlay1 overlay-primary" >
      <img src={imageBlog} alt="" />
      <div className="overlay-bx">
        <div className="overlay-icon">
          <a href="https://www.youtube.com/watch?v=Dj6CKxQue7U" className="popup-youtube">
            <i className="fa fa-play icon-bx-xs"></i>
          </a>
          <a onClick={() => openLightbox(props.imageToOpen)} className=" " >
            <i className="fa fa-search icon-bx-xs"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

class MoveInHomesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleSwitch: false,
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      loading: true,
      dataInfo: [],
      isHover: false,
      link: window.location.href.split('/').pop(),
    };
    // this.tog_scroll = this.tog_scroll.bind(this);
  }
  componentDidMount() {
    new WOW.WOW().init();
    this.dataFetch();
    this.fetchFloorPlanIcon();
    this.fetchReadyHomes();
    let url = this.state.link
    this.fetchFloorPlanDetails(url);
    this.fetchButtonSettings();

  }


  fetchReadyHomes = () => {
    moveInReadyHomes().then(response => {
      this.setState({
        readHomes: response.data,
      })
    })
  }

  fetchButtonSettings = () => {
    bookButtonSettings().then(response => {
      this.setState({
        buttonStatus: response.data,
      })
    })
  }

  fetchFloorPlanDetails = (url) => {
    moveInPlanDetailssdvdfverfd(url).then(response => {
      this.setState({
        floorPlanDetails: response.data[0]
      })
    })
  }

  fetchFloorPlanIcon = () => {
    floorIcon().then(response => {
      this.setState({
        floorPlanIcon: response.data,
      })
    })
  }

  dataFetch = () => {
    // let url = window.location.pathname.split('/').pop();
    this.setState({
      dataInformation: data
    })
    // let dataInfo = data && data.filter(dataInfo => dataInfo.slug == url).map(d => d);
    this.setState({
      dataInfo: data
    })

  }

  handleMouseEnter = () => {
    this.setState({
      isHover: true,
    })
  };
  handleMouseLeave = () => {
    this.setState({
      isHover: false,
    })
  };

  handleLink = (slug) => {
    this.props.history.push(`/floor-plans/${slug}`)
    this.setState({
      link: slug
    })
    this.state.link = slug;
    this.fetchFloorPlanDetails(this.state.link)
  }



  render() {
    var settings = {
      arrows: true,
      dots: true,
      slidesToShow: 1,
      infinite: true,
      autoplay: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,

          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,

          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,

          }
        }
      ]
    };

    const { isHover } = this.state;
    const imageLink = process.env.REACT_APP_IMAGE_URL;

    const { id, address, areaDetails, details, iconList, pdfLink1, pdfLink2, price, slug, title, type } = this.state.dataInfo;
    let data = [];
    if (this.state.hasOwnProperty("dataInformation")) {
      data = this.state.dataInformation;
    }

    let floorPlanDetails = [];
    let floorPlanIcon = [];
    let readHomes = [];
    let coverImage = [];
    let brochure = [];
    let feature_image = [];
    let sub_com_details = [];
    let buttonStatus = [];
    if (this.state.hasOwnProperty('floorPlanIcon')) {
      floorPlanIcon = this.state.floorPlanIcon;
    }
    if (this.state.hasOwnProperty('readHomes')) {
      readHomes = this.state.readHomes;
    }

    if (this.state.hasOwnProperty('buttonStatus')) {
      buttonStatus = this.state.buttonStatus;
    }
    if (this.state.hasOwnProperty("floorPlanDetails")) {
      floorPlanDetails = this.state.floorPlanDetails;
      coverImage = this.state.floorPlanDetails && this.state.floorPlanDetails.sub_com_details[0];
      feature_image = this.state.floorPlanDetails && this.state.floorPlanDetails.feature_image;
      sub_com_details = this.state.floorPlanDetails && this.state.floorPlanDetails.sub_com_details[0];
    }

    let homeDetails = <div dangerouslySetInnerHTML={{ __html: floorPlanDetails && floorPlanDetails.details_descrptions }} />

    let bathroom_details = <span dangerouslySetInnerHTML={{ __html: htmlToText(floorPlanDetails?.bathroom_details) }} />

    brochure = feature_image && feature_image.filter(imgInfo => imgInfo.is_brochure == 1 && imgInfo.is_active == 1).map((imgInfo, index) => imgInfo)
    let url = window.location.href.split('/').pop();
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    var strippedHtml = floorPlanDetails?.details_descrptions?.replace(/<[^>]+>/g, '').slice(0, 300);
    return (
      <Fragment>
        <Helmet>
          <title>{capitalizeFirstLetter(url)} -Aspen Square Homes</title>
          <meta name="description" content={strippedHtml} />
          <meta property="og:title" content={`${capitalizeFirstLetter(url)} -Aspen Square Homes`} />
          <meta property="og:type" content="aspensquarehomes" />
          <meta property="og:description" content={strippedHtml} />
          <meta property="og:url" content="https://aspensquarehomes.com/" />
          <meta property="og:image" content={`${imageLink + '/company_settings/aboutus/sub/project_cover_image_1/' + coverImage.project_cover_image_1}`} />
        </Helmet>
        <Header />
        <div className="page-content bg-white">
          {/*  banner  */}
          <PageTitle motherMenu={floorPlanDetails && floorPlanDetails.floore_plans_names} activeMenu={floorPlanDetails && floorPlanDetails.floore_plans_names} breadcrumbImage={coverImage === undefined ? "" : imageLink + '/company_settings/aboutus/sub/project_cover_image_1/' + coverImage.project_cover_image_1} />
          {/*  Section-1 Start  */}
          <section className="content-inner" data-content={floorPlanDetails && floorPlanDetails.floore_plans_names}>
            <div className="container">
              <div className="d-flex flex-column justify-content-center">
                <h2 className="double-rules wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">{floorPlanDetails && floorPlanDetails.floore_plans_names}</h2>
                <div className="entry-content text-center wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                  <p>
                    {floorPlanDetails && floorPlanDetails.address}
                  </p>
                </div>
                <p className='text-right mr-3 fw-bold double-rules wow fadeInDown' data-wow-duration='2s' data-wow-delay="0.4s"> {floorPlanDetails && floorPlanDetails.is_move_in_ready === 1 ? "Quick Ready Home" : "Upcoming Home"}</p>
              </div>
              <section className="content-inner exhibition-bx" style={{ paddingTop: '0px' }}>
                <div className="container">

                  <div className="row mt-2">
                    <div className="col-lg-6 wow fadeInLeft" data-wow-duration='2s' data-wow-delay="0.4s">
                      <Slider className="exhibition-carousel owl-carousel owl-none m-b30 " {...settings}>
                        {feature_image && feature_image.filter(imgInfo => imgInfo.is_brochure != 1 && imgInfo.is_active == 1).map((imgInfo, index) =>

                          <div className="item">
                            <img className="w-100" src={imageLink + 'MoveIn/' + imgInfo.f_floor_plane_id + '/Slider/large/' + imgInfo.slider_large_image} alt="" />
                          </div>
                        )}
                      </Slider>
                      <div className="company-info">
                        <h3 className="text-uppercase wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s"> Home Details</h3>
                        <p className="wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.6s">{homeDetails}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s">
                      <aside className="sticky-top">
                        <div className="card">
                          <div className="card-body p-2 card-body-design2">
                            <div>
                              <h3 className="text-primary text-center">From ${floorPlanDetails?.price && (floorPlanDetails?.price).toLocaleString()}</h3>
                              <p className="text-primary text-center">{floorPlanDetails && floorPlanDetails.size_details_sq_fit} Sq. Ft.</p>
                              <div className="col-md-12 d-flex justify-content-center px-0">
                                <table className="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <td>
                                        {floorPlanIcon && floorPlanIcon.filter((floor) => floor.filter_name == "Stories").map((floor, index) =>
                                          <img className="icon-book-page" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                        )
                                        }
                                        <span className="icon-book-page-title ml-2">{floorPlanDetails && floorPlanDetails.stories_qty} Stories</span>
                                      </td>
                                      <td>
                                        {floorPlanIcon && floorPlanIcon.filter((floor) => floor.filter_name == "Bedroom").map((floor, index) =>
                                          <img className="icon-book-page" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                        )}
                                        <span className="icon-book-page-title ml-2">{floorPlanDetails && floorPlanDetails.bedroom_qty} Beds</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {floorPlanIcon && floorPlanIcon.filter((floor) => floor.filter_name == "Garage").map((floor, index) =>
                                          <img className="icon-book-page" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                        )}
                                        <span className="icon-book-page-title ml-2">{floorPlanDetails && floorPlanDetails.garage_qty} Car Garage</span>
                                      </td>
                                      <td>
                                        {floorPlanIcon && floorPlanIcon.filter((floor) => floor.filter_name == "Bathrooms").map((floor, index) =>
                                          <img className="icon-book-page" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                        )}
                                        <span className="icon-book-page-title ml-2">{bathroom_details}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="align-items-center mt-2">
                                <p className="text-center"></p>
                                {
                                  buttonStatus.map(settings =>
                                    settings.settings_name == "book_now_button" && settings.status == "1" && <button className="btn w-100 rounded-0 py-1" style={isHover == true ? cardButtonHover('#000', '#fff', '#569539') : cardButton('#569539', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()} onClick={() => this.props.history.push(`/booking/${floorPlanDetails.id}`)}><i className="fa fa-calendar"></i> Book Now</button>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          floorPlanDetails && floorPlanDetails.vedio_link == null || floorPlanDetails.vedio_link == undefined || floorPlanDetails.vedio_link == "" ? '' :
                            <div>
                              <iframe width="100%" height="350px" style={{ border: '10px solid #fff', boxShadow: '0px 0px 10px 10px #8EC64C' }} src={floorPlanDetails && floorPlanDetails.vedio_link} title="2 STORIED ANIMATION" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        }
                      </aside>


                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
          {brochure && brochure.length == 0 ? '' :
            <section className="content-inner" style={{ backgroundColor: 'rgba(0, 0, 0, 0.20)' }}>
              <div className="container">
                <Fade bottom>
                  <div className="d-flex flex-column justify-content-center">
                    <h2 className="double-rules">FLOOR PLANS</h2>
                  </div>
                </Fade>
                <Fade bottom>
                  <div>
                    <div className="row">
                      {/* <div className="col-md-2">
                                </div> */}
                      <div className="col-md-12">
                        {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <div className="d-flex justify-content-center custom-toggle-button">
                                            <div className="btn-group wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s" role="group" aria-label="Basic example">
                                                <Nav className="flex-row">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first"><button eventKey="first" type="button" className="btn  border-0">Front</button></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second">  <button eventKey="second" type="button" className="btn  border-0">Rear</button></Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>
                                        </div> */}
                        {/* <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <iframe
                                                    src={pdfLink1}
                                                    frameBorder="0"
                                                    scrolling="none"
                                                    height="550px"
                                                    width="100%"
                                                    style={{ border: '10px solid #a6611c' }}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second"> */}

                        {brochure && brochure.map((imgInfo, index) =>
                          < iframe
                            key={index}
                            src={imageLink + 'MoveInB/brochure/' + imgInfo.slider_large_image}
                            frameBorder="0"
                            scrolling="none"
                            height="650px"
                            width="100%"
                            style={{ border: '10px solid #569539' }}
                          />
                        )
                        }

                      </div>

                    </div>

                  </div>
                </Fade>


              </div>
            </section>

          }

          {
            readHomes && readHomes.length == 0 ? '' :
              <section className="content-inner" style={{ backgroundColor: '#89afc9' }}>
                <div className="container">
                  <Fade bottom>
                    <div className="d-flex flex-column justify-content-center">
                      <h2 className="double-rules text-white">MOVE-IN READY HOMES</h2>
                    </div>
                  </Fade>
                  <Fade bottom>
                    <div className="row justify-content-md-center">
                      {
                        readHomes && readHomes.map((f, index) =>
                          <div className="col-lg-3 col-md-6 col-sm-12 ">
                            <Fade bottom>
                              <div className="card">
                                <div>
                                  <Link to={`/floor-plans/${f.slug}`}>
                                    <h5 className="text-center p-2 mb-0 font-weight-bold text-white bg-dark text-uppercase">{f.floore_plans_names}</h5>
                                    <img src={imageLink + 'FloorPlan/' + f.feature_image[0].f_project_names_id + '/Slider/thumbnalie/' + f.feature_image[0].slider_large_image} className="card-img-top" alt="..." />
                                    <p className="old-ribbon mb-0"><Link to={`/floor-plans/${f.slug}`} className="text-white text-uppercase">Ready Now</Link></p>
                                  </Link>
                                </div>
                                <div className="card-body p-2 card-body-design">
                                  <div>
                                    <h5 className="text-center"><Link to={`/floor-plans/${f.slug}`}>{f.address}</Link> <i className="fa fa-location-arrow"></i></h5>
                                    <div className="col-md-12 d-flex justify-content-center px-0">
                                      <table className="table table-bordered">
                                        <tbody>
                                          <tr>
                                            <td>

                                              <img className="icon" src="https://cbjenihomes.com/wp-content/uploads/2020/06/floorplan-icon.svg" />
                                              <span className="icon-title ml-2">Naples</span>
                                            </td>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Size").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.size_details_sq_fit} Sq. Ft.</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Stories").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.stories_qty} Stories</span>
                                            </td>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bedroom").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.bedroom_qty} Beds</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Garage").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.garage_qty} Car Garage</span>
                                            </td>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.bathroom_qty} Baths</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                      <span className="text-dark price-title font-weight-bold">${f.price}</span>
                                      <a className="text-dark" href="#"><span className="price-title font-weight-bold">Save <i className="fa fa-heart-o"></i></span></a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer p-0">
                                  <button className="btn btn-primary w-100 rounded-0 py-1" style={cardButton('#000', '#fff', '#569539')} onClick={() => this.handleLink(f.slug)}>View Floor Plans</button>
                                </div>
                              </div>
                            </Fade>
                          </div>
                        )
                      }
                    </div>
                  </Fade>
                </div>
              </section>
          }



          <section className="content-inner">

            <div className="container">
              <div className="d-flex flex-column justify-content-center">
                <h2 className="double-rules wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">COMMUNITY SALES INFORMATION</h2>
              </div>
              <div className="row">
                <div className="col-md-4 wow fadeInLeft" data-wow-duration='2s' data-wow-delay="0.4s">
                  <div className="card">
                    {sub_com_details != undefined ?
                      <>
                        {sub_com_details && <img src={imageLink + 'company_settings/aboutus/sub/project_logo/' + sub_com_details.project_logo} className="card-img-top w-100 h-100" alt="..." />}
                        {/* <h6 className="logo-title-mobile text-center mb-0 py-0 text-primary" style={{ backgroundColor: "rgba(0, 0, 0, 0.1" }}>Exceptional Excellence</h6> */}
                      </>

                      : ''
                    }
                    <div className="card-body rounded-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.1" }}>
                      <h5 className="card-title text-uppercase">Aspen Square</h5>
                      <hr className="text-dark border-1" />
                      <i className="card-text">Community Sales Manager</i>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.4s" >
                  <div className="bg-dark" style={{ height: '180px ' }}>
                    <h4 className="text-center text-white pt-2">
                      <u className="text-white">Visit Our Community Sales Office</u>
                    </h4>
                    {/* {
                                url == "floor-plans" ? <p className="text-center text-white" style={{ fontSIze: '8px' }}>
                                   13151 Emily Rd, Suite 201, Dallas, TX-75240
                                </p> : url == "merritt-villas-townhomes" ? <p className="text-center text-white" style={{ fontSIze: '8px' }}>
                                    13151 Emily Rd, Suite 201, Dallas, Texas - 75240
                                </p> : url == "gilbert-villas-townhomes" ? <p className="text-center text-white" style={{ fontSIze: '8px' }}>
                                    Gilbert Villas, 108 E Shady Grove Irving, TX 75060
                                </p> :  */}
                    <p className="text-center text-white" style={{ fontSIze: '8px' }}>
                      {sub_com_details && sub_com_details.contact_information_description}
                    </p>
                    {/* } */}

                    <p className="text-center text-white">Monday – Thursday : 10AM to 6PM , Friday – Saturday : 11AM to 4PM, Sunday : Close</p>
                  </div>
                  <div className="mt-2 bg-dark" style={{ height: '150px ' }}>
                    <h4 className="text-center text-white pt-2">
                      <u className="text-white">Reach Out Now</u>
                    </h4>
                    <div className="d-flex d- justify-content-center">
                      <Link to="/contact-us"><button className="btn btn-sm btn-outline-light rounded-0 mx-1"><i className="fa fa-calendar"></i> Book Now</button></Link>
                      <a style={{color:'white'}} href={"tel:"+(sub_com_details && sub_com_details.project_phone_no)}>
                      <button className="btn btn-sm btn-outline-light rounded-0 mx-1"><i className="fa fa-phone"></i> {sub_com_details && sub_com_details.project_phone_no}</button>
                      </a>
                      <Link to="/contact-us"><button className="btn btn-sm btn-outline-light rounded-0 mx-1"><i className="fa fa-envelope"></i> Contact Us</button></Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 m-b30 mt-2 wow fadeInUp" data-wow-duration='2s' data-wow-delay="0.6s">
                  <div className="section-head">
                    <form className="contact-box dzForm p-a30 border-1" action="script/contact.php">
                      <h3 className="title-box">REQUEST MORE INFORMATION</h3>
                      <div className="dzFormMsg m-b20"></div>
                      <input type="hidden" value="Contact" name="dzToDo" />
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="input-group">
                              <input name="dzFirstName" type="text" required="" className="form-control" placeholder="First Name*" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="input-group">
                              <input name="dzLastName" type="text" required="" className="form-control" placeholder="Last Name*" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <div className="input-group">
                              <input name="dzEmail" type="email" className="form-control" required="" placeholder="Enter Address*" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="input-group">
                              <input name="dzOther[Phone]" type="text" required="" className="form-control" placeholder="Phone Number*" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="input-group">
                              <input name="dzZipCode" type="text" className="form-control" required="" placeholder="Zip Code*" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <div className="input-group">
                              <textarea name="dzMessage" rows="4" className="form-control" required="" placeholder="Question"></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group form-recaptcha">
                            <div className="input-group">
                              <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                              <input className="form-control d-none" style={{ display: "none" }} data-recaptcha="true" required data-error="Please complete the Captcha" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg" style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#000', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()}>Submit</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <Link to={"/contact-us-1"} className="btn btn-primary m-r10 m-b10">Contact us</Link>
      			<Link to={"/portfolio-1"} className="btn btn-primary m-b10">View Portfolio</Link> */}
                </div>
              </div>

            </div>

          </section>
          {/*  Section-1 End  */}
          <div>
            <img src={coverImage == undefined ? "" : imageLink + '/company_settings/aboutus/sub/project_cover_image_2/' + coverImage.project_cover_image_2} className="bg-view" alt="" />
          </div>
        </div>
        <Footer2 />
      </Fragment>
      // <>

      //   hello
      // </>
    )
  }
}
export { GalleryBox };
export default MoveInHomesDetails;