import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FacebookProvider, Page } from 'react-facebook';

import WOW from 'wowjs';

import Footer2 from './../../Layout/Footer2';
import SideNav from './../../Element/SideNav';
import ReactPlayer from 'react-player'



import Header from '../../Layout/Header';
import Carousel from 'react-bootstrap/Carousel';
import './Homepage.css';
import ComingSoon from '../ComingSoon';
import { Form, Button, Dropdown, Modal, Table } from 'react-bootstrap';
import data from '../../../staticData/staticData.js';
import AspenSquare_LOGOColor from '../../../images/companyLogo/AspenSquare_LOGOColor.png';

// images connect section 

import EventSlider from '../../Element/EventSlider';
import bgimg from './../../../images/bg-view.png';


import { inputButtonHover, inputButton, inputButtonPadding, cardButtonHover, cardButton, homePageVision, homePageVisionBg, htmlToText } from '../../CommonStyle/CommonStyle';
import { homePageSlider, moveInReadyHomes, upcomingCommunities } from '../../../Api/Actions/homePageApi';
import { companyInfo } from '../../../Api/Actions/companyInfo';
import { city } from '../../../Api/Actions/city';
import SliderSection from './SectionItem/SliderSection';
import Fade from 'react-reveal/Fade';
import { floorIcon } from '../../../Api/Actions/floorplan';
import Vision from './SectionItem/Vision';
import FacebookPage from './SectionItem/FacebookPage';
import ReactModal from '../../Modal/ReactModal';
import { getPromotion } from '../../../Api/Actions/promotion';
import { Helmet } from 'react-helmet';
import StaticVideo from "../../../assets/LandingPage/home-page video.mov"

class Index1 extends React.Component {
  constructor(props) {
    super(props);
    // this.videoRef = React.createRef();
    this.state = {

      quick_move: false,
      cityInfo: "",
      dataInfo: [],
      isHover: false,
      isOpen2: false,
      modalShow: false,
      modalData: []
    }
    this.wrapper = React.createRef();

  }
  componentDidMount() {
    this.dataFetch();
    const wow = new WOW.WOW();
    wow.init();

    var GalleryCategory = document.querySelectorAll('.gallery-category .items');
    var GalelryMedia = document.querySelectorAll('.gallery-img img');

    var fch = [].slice.call(GalleryCategory);
    var fcMedia = [].slice.call(GalelryMedia);


    for (var y = 0; y < fch.length; y++) {
      fch[y].addEventListener('mouseenter', function () {
        galleryActive(this);
      });
    }

    function galleryActive(current) {
      fcMedia.forEach(el => el.classList.remove('active'));

      setTimeout(() => {
        var dataImageBx = current.getAttribute('data-image-bx');
        document.querySelector('#' + dataImageBx).classList.add('active');
      }, 100);
    }
    this.fetchHomePageSlider();
    this.fetchCompanyInfo();
    this.fetchReadyHomes();
    this.fetchCity();
    this.fetchUpcomingCommunities();
    this.fetchFloorPlanIcon();
    this.promotionData();
    // this.videoRef.current.addEventListener(
    //   "contextmenu",
    //   function (e) {
    //     e.preventDefault();
    //   },
    //   false
    // );

    // return this.videoRef.current.removeEventListener(
    //   "contextmenu",
    //   function (e) {
    //     e.preventDefault();
    //   },
    //   false
    // );
  }
  // Api Connection

  fetchHomePageSlider = () => {
    homePageSlider().then(response => {
      this.setState({
        homeSlider: response.data,
      })
    })
  }


  fetchCompanyInfo = () => {
    companyInfo().then(response => {
      this.setState({
        companyInfo: response.data,
      })
    })
  }

  fetchReadyHomes = () => {
    moveInReadyHomes().then(response => {
      this.setState({
        readHomes: response.data,
      })
    })
  }

  fetchCity = () => {
    city().then(response => {
      this.setState({
        city: response.data,
      })
    })
  }

  fetchUpcomingCommunities = () => {
    upcomingCommunities().then(response => {
      this.setState({
        upcomingCommunities: response.data,
      })
    })
  }

  fetchFloorPlanIcon = () => {
    floorIcon().then(response => {
      this.setState({
        floorPlanIcon: response.data,
      })
    })
  }


  autoSearchInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name == 'cityInfo') {
      this.setState({
        cityInfo: value
      })
      this.state.cityInfo = value
    }
    if (name == 'quick_move') {
      this.setState({
        quick_move: checked
      })
      this.state.quick_move = checked
    }
  }

  onHandleSubmit = (e) => {
    e.preventDefault();
    const { quick_move, cityInfo } = this.state;
    const data = {
      quick_move: quick_move,
      cityInfo: cityInfo,
    }
    this.props.history.push({
      pathname: '/find-home',
      state: { data: data }
    });
  }
  dataFetch = () => {
    this.setState({
      dataInfo: data
    })
  }

  handleMouseEnter = () => {
    this.setState({
      isHover: true,
    })
  };
  handleMouseLeave = () => {
    this.setState({
      isHover: false,
    })
  };

  openModal2 = () => {
    this.setState({ isOpen2: true })
  };
  closeModal2 = () => {
    this.setState({ isOpen2: false });
  };
  promotionData = () => {
    getPromotion()
      .then(res => {
        if (res.data.length > 0) {
          setTimeout(() => this.setState({ modalShow: true }), 1000);
        }
        this.setState({
          modalData: res.data
        })
      })
      .catch(err => console.log(err))
  }
  render() {
    const imageLink = process.env.REACT_APP_IMAGE_URL;

    const { isHover } = this.state;
    // const MAX_LENGTH = 350;
    // const content = 'Aspen Square excels in providing investment-grade properties in well-researched strategic locations. We focus on creating value for investment in the shortest possible time with minimum exposure to market risk. The team always ensures that investors are safe from market turmoil by introducing projects that are recession-proved and highly sought after in any economic condition.';

    // Data Fetch
    let homeSlider = [];
    let companyInfo = [];
    let readHomes = [];
    let city = [];
    let upcomingCommunities = [];
    let floorPlanIcon = [];
    if (this.state.hasOwnProperty('homeSlider')) {
      homeSlider = this.state.homeSlider;
    }
    if (this.state.hasOwnProperty('companyInfo')) {
      companyInfo = this.state.companyInfo[0];
    }
    if (this.state.hasOwnProperty('readHomes')) {
      readHomes = this.state.readHomes;
    }

    if (this.state.hasOwnProperty('city')) {
      city = this.state.city;
    }


    if (this.state.hasOwnProperty('upcomingCommunities')) {
      upcomingCommunities = this.state.upcomingCommunities;
    }

    if (this.state.hasOwnProperty('floorPlanIcon')) {
      floorPlanIcon = this.state.floorPlanIcon;
    }


    let ourVisionShortDesc = <div dangerouslySetInnerHTML={{ __html: companyInfo.our_vision_short_description }} />
    let ourVisionDetails = <div dangerouslySetInnerHTML={{ __html: htmlToText(companyInfo.our_vision_details_description) }} />
    var strippedHtml = companyInfo.our_vision_details_description?.replace(/<[^>]+>/g, '').slice(0, 300);
    console.log(window.innerWidth);
    return (
      <Fragment>
        <Helmet>
          <title>Home -Aspen Square Homes</title>
          <meta name="description" content={strippedHtml} />
          <meta property="og:title" content="Home -Aspen Square Homes" />
          <meta property="og:type" content="aspensquarehomes" />
          <meta property="og:description" content={strippedHtml} />
          <meta property="og:url" content="https://aspensquarehomes.com/" />
          <meta property="og:image" content={`${imageLink + 'company_settings/dashboard/slider_large_image/' + homeSlider[0]?.slider_large_image}`} />
        </Helmet>
        <ReactModal
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
          data={this.state.modalData}
        />
        <div className="sidenav-list">
          <SideNav />
        </div>

        <Header />
        {/* <!-- Main Slider --> */}
        <div className="page-content bg-white">

          <SliderSection
            homeSlider={homeSlider}
            companyInfo={companyInfo}
            city={city && city}
            isHover={isHover}
            handleMouseEnter={this.handleMouseEnter}
            handleMouseLeave={this.handleMouseLeave}
            autoSearchInputChange={this.autoSearchInputChange}
            onHandleSubmit={this.onHandleSubmit}
            quick_move={this.state.quick_move}
            cityInfo={this.state.cityInfo}
          />

          <Vision
            companyInfo={companyInfo}
            ourVisionShortDesc={ourVisionShortDesc}
            ourVisionDetails={ourVisionDetails}
          />
          {/* <div className='w-100 my-5 d-flex justify-content-center'>
            <div ref={this.videoRef} style={{ border: '10px solid #fff', boxShadow: '0px 0px 10px 10px #8EC64C', width: "60%" }}>
              <ReactPlayer
                width="100%"
                height="100%"
                controls={true}
                url={
                  "https://aspensquarehomes.com/Aspen%20Square%20Homes_BrandFilm_V2.mov"
                }
                config={{ file: { attributes: { controlsList: "nodownload" } } }}
                loop={true}
                playing={true}
              />
            </div>
          </div> */}
          {
            readHomes.length === 0 ? '' :
              <section className="content-inner" data-content="NEW & NOW" id="sidenav_masterPlan">
                <div className="container">
                  <Fade bottom>
                    <div className="d-flex flex-column justify-content-center">
                      <h2 className="double-rules">NEW & NOW</h2>
                      <h3 className="ribbon red aos-init aos-animate text-uppercase "><span className="text-white">Move-In Ready Homes</span></h3>
                      <div className="entry-content text-center my-2">
                        <p>
                          Finding move-in ready town homes where you want to live isn’t always easy. Our town homes available for quick move-in.
                        </p>
                      </div>
                    </div>
                  </Fade>
                  <Fade bottom>
                    <div className="row justify-content-md-center">
                      {
                        readHomes && readHomes.map((f, index) =>
                          <div className="col-lg-3 col-md-6 col-sm-12 ">
                            <Fade bottom>
                              <div className="card" key={index}>
                                <div>
                                  <Link to={`/floor-plans/${f.slug}`}>
                                    <h5 className="text-center p-2 mb-0 font-weight-bold text-white bg-dark text-uppercase">{f.floore_plans_names}</h5>
                                    <img src={imageLink + 'FloorPlan/' + f.feature_image[0].f_project_names_id + '/Slider/thumbnalie/' + f.feature_image[0].slider_large_image} className="card-img-top" alt="..." />
                                    <p className="old-ribbon mb-0"><Link to={`/floor-plans/${f.slug}`} className="text-white text-uppercase">Ready Now</Link></p>
                                  </Link>
                                </div>
                                <div className="card-body p-2 card-body-design">
                                  <div>
                                    <h5 className="text-center"><Link to={`/floor-plans/${f.slug}`}>{f.address}</Link> <i className="fa fa-location-arrow"></i></h5>
                                    <div className="col-md-12 d-flex justify-content-center px-0">
                                      <table className="table table-bordered">
                                        <tbody>
                                          <tr>
                                            <td>

                                              <img className="icon" src="https://cbjenihomes.com/wp-content/uploads/2020/06/floorplan-icon.svg" />
                                              <span className="icon-title ml-2">Naples</span>
                                            </td>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Size").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.size_details_sq_fit} Sq. Ft.</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Stories").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.stories_qty} Stories</span>
                                            </td>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bedroom").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.bedroom_qty} Beds</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Garage").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.garage_qty} Car Garage</span>
                                            </td>
                                            <td>
                                              {floorPlanIcon && floorPlanIcon.filter((floor, index) => floor.filter_name == "Bathrooms").map(floor =>
                                                <img className="icon" src={imageLink + 'icon/' + floor.filter_icon} alt="icon" />
                                              )
                                              }
                                              <span className="icon-title ml-2">{f.bathroom_qty} Baths</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                      <span className="text-dark price-title font-weight-bold">${f.price}</span>
                                      <a className="text-dark" href="#"><span className="price-title font-weight-bold">Save <i className="fa fa-heart-o"></i></span></a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer p-0">
                                  <button className="btn btn-primary w-100 rounded-0 py-1" style={cardButton('#000', '#fff', '#569539')} onClick={() => this.props.history.push(`/floor-plans/${f.slug}`)}>View Floor Plans</button>
                                </div>
                              </div>
                            </Fade>
                          </div>
                        )
                      }
                    </div>
                  </Fade>
                  <Fade bottom>
                    <div className="d-flex justify-content-center">
                      <div>
                        <button className="btn btn-primary w-100 rounded-0 " style={isHover == true ? cardButtonHover('#569539', '#fff', '#fff') : cardButton('#000', '#fff', '#569539')} onMouseOver={() => this.handleMouseEnter()} onMouseLeave={() => this.handleMouseLeave()} onClick={() => { this.props.history.push('/quick-move') }}>See More Homes</button>
                      </div>
                    </div>
                  </Fade>
                </div >
              </section >
          }


          <section className="content-inner" style={{ backgroundColor: 'rgba(179, 178, 178, 0.354' }} data-content="SOCIAL" id="social">
            <div className="container">
              <div className="d-flex flex-column justify-content-center">
                <h2 className="double-rules wow fadeInDown" data-wow-duration='2s' data-wow-delay="0.4s">SOCIAL MEDIA POST</h2>
              </div>
              <div className="row wow fadeInLeft" data-wow-duration='2s' data-wow-delay="0.6s">

                <div className="col-md-12 mx-auto">
                  {/* <FacebookPage /> */}
                  {/* <div className="d-flex justify-content-center"> */}
                  <center>
                    {/* <iframe title='facebook' src={`https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/Aspensquaretownhomes&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=917905122451656`} width="520" height="500" style={{ border: "none", overflow: "hidden", margin: '0 auto', border: '10px solid #569539' }} className="social-media" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}
                    <iframe src={`https://www.facebook.com/plugins/page.php?href=${companyInfo && companyInfo.dashboard_youtube_link_2}&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=917905122451656`} width="520" height="500" style={{ border: "none", overflow: "hidden", margin: '0 auto', border: '10px solid #569539' }} className="social-media" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                  </center>
                  {/* </div> */}


                </div>
                <div class="col-md-12 mt-3">
                  <div className="dlab-social-icon">
                    <ul className="d-flex justify-content-center">
                      {/* <li><a href={companyInfo && companyInfo.dashboard_youtube_link_2} target="_blank" className="fa fa-facebook mx-2 fa-2x text-primary"></a></li> */}
                      <li><a href={companyInfo && companyInfo.dashboard_youtube_link_4} target="_blank" className="fa fa-twitter mx-2 fa-2x text-primary"></a></li>
                      <li><a href={companyInfo && companyInfo.dashboard_youtube_link_1} target="_blank" className="fa fa-youtube mx-2 fa-2x text-primary"></a></li>
                      <li><a href={companyInfo && companyInfo.dashboard_youtube_link_3} target="_blank" className="fa fa-instagram mx-2 fa-2x text-primary"></a></li>
                      <li><a href={companyInfo && companyInfo.dashboard_youtube_link_5} target="_blank" className="fa fa-pinterest mx-2 fa-2x text-primary"></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </section>
          {
            companyInfo.dashboard_our_foot_print_map == null ? '' :
              <section className="content-inner" data-content="FOOTPRINT" id="sidenav_connect">
                <div className="container">
                  <div className="d-flex flex-column justify-content-center">
                    <h2 className="double-rules">Our Footprint</h2>
                  </div>
                  <div className="banner-map">
                    <iframe src={companyInfo.dashboard_our_foot_print_map} className="border-0 align-self-stretch rounded-sm" style={{ border: "0", width: "100%", minHeight: "500px" }} allowfullscreen alt="Map Footprint"></iframe>
                  </div>
                </div>
              </section>
          }

          {
            upcomingCommunities.length == 0 ? '' :
              <section className="content-inner" data-content="UP COMING">
                <div className="container">
                  <Fade bottom>
                    <div className="d-flex flex-column justify-content-center">
                      <h2 className="double-rules">Up Coming Communities</h2>
                    </div>
                  </Fade>
                  <Fade bottom>
                    <div className="row">
                      <div className="col-md-12">
                        <EventSlider imageLink={imageLink} upcomingCommunities={upcomingCommunities} />
                      </div>
                    </div>
                  </Fade>
                </div>
              </section>
          }

        </div >
        <img src={bgimg} className="bg-view" alt="" />

        <Footer2 />
      </Fragment >
    )
  }
}

export default Index1;