import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import Header2 from './../../Layout/Header2';
import Footer2 from './../../Layout/Footer2';
import WOW from 'wowjs';

import post1 from './../../../images/trending-post/pic1.jpg';
import post2 from './../../../images/trending-post/pic2.jpg';
import post3 from './../../../images/trending-post/pic3.jpg';
import post4 from './../../../images/trending-post/pic4.jpg';
import blog1 from './../../../images/blog/blog-grid/pic1.jpg';
import blog2 from './../../../images/blog/blog-grid/pic2.jpg';
import blog3 from './../../../images/blog/blog-grid/pic3.jpg';
import blog4 from './../../../images/blog/blog-grid/pic4.jpg';
import blog5 from './../../../images/blog/blog-grid/pic5.jpg';
import blog6 from './../../../images/blog/blog-grid/pic6.jpg';
import bgimg from './../../../images/bg-view.png';
import Header from '../../Layout/Header';
import PageTitle from '../../Layout/PageTitle';
import breadcrumbImage from '../../../images/banner.png';
import { article } from '../../../Api/Actions/Article';
import { Helmet } from 'react-helmet';


const MediaBlog = ({ postImage }) => {
  return (
    <>
      <div className="dlab-media">
        <img src={postImage} alt="" />
      </div>
      <div className="dlab-info">
        <div className="dlab-meta">
          <ul>
            <li className="post-date"><i className="las la-calendar-alt"></i><span>March 29, 2020</span></li>
            <li className="post-user"><i className="las la-user"></i>By <Link to={"#"}>Deo Admin</Link></li>
          </ul>
        </div>
        <h3 className="dlab-title">
          <Link to={"/article-details"}>How Roofing Charge for Their Services Asking the Right Questions</Link>
        </h3>
      </div>
    </>
  )
}

const gridBlog = [
  { media: blog1 },
  { media: blog2 },
  { media: blog3 },
  { media: blog4 },
  { media: blog5 },
  { media: blog6 },
];

function Pagination() {
  return (
    <>
      <ul className="pagination justify-content-center wow bounceInUp" data-wow-duration="2s" data-wow-delay="0.4s">
        <li className="page-item disabled">
          <Link className="page-link" to={"#"} tabindex="-1" aria-disabled="true">Previous</Link>
        </li>
        <li className="page-item"><Link className="page-link" to={"#"}>1</Link></li>
        <li className="page-item active"><Link className="page-link" to={"#"}>2</Link></li>
        <li className="page-item"><Link className="page-link" to={"#"}>3</Link></li>
        <li className="page-item">
          <Link className="page-link" to={"#"}>Next</Link>
        </li>
      </ul>
    </>
  )
}

class Article extends Component {

  constructor(props) {
    super(props);
    this.state = {
      toggleSwitch: false,
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      loading: true,
    };
  }

  componentDidMount() {
    new WOW.WOW().init();
    this.fetchArticle();
  }

  fetchArticle = () => {
    article().then(response => {
      this.setState({ article: response.data })
    })
  }

  render() {

    const imageLink = process.env.REACT_APP_IMAGE_URL;
    let articleList = [];

    if (this.state.hasOwnProperty('article')) {
      articleList = this.state.article;
    }

    return (
      <Fragment>
        <Helmet>
          <title>Articles -Aspen Square Homes</title>
          <meta name="description" content="Our mission is to enrich our customers' lives with an exceptional customer experience by connecting our customers with skillful and professional team members, provide innovative craftsmanship, designs, and products, and offer utmost personal care — one customer, one home, and one community at a time." />
          <meta property="og:title" content="Articles -Aspen Square Homes" />
          <meta property="og:type" content="aspensquarehomes" />
          <meta property="og:description" content="Our mission is to enrich our customers' lives with an exceptional customer experience by connecting our customers with skillful and professional team members, provide innovative craftsmanship, designs, and products, and offer utmost personal care — one customer, one home, and one community at a time." />
          <meta property="og:url" content="https://aspensquarehomes.com/articles" />
          <meta property="og:image" content={breadcrumbImage} />
        </Helmet>
        <Header />
        <div className="page-content bg-white">
          {/*  Main Slider */}
          <div className="page-content bg-white">
            {/*  banner  */}
            <PageTitle motherMenu="Articles" activeMenu="Articles" breadcrumbImage={breadcrumbImage} />
            {/*  Section-1 Start  */}
            <section className="content-inner" data-content="ARTICLES">
              <div className="container">
                <div className="d-flex flex-column justify-content-center">
                  <h2 className="double-rules wow fadeInDown" data-wow-duration="2s" data-wow-delay="0.4s">ARTICLES</h2>
                </div>
              </div>
              {/* <section className="section-full post-cobble bg-white">
                                <div className="row sp4">
                                    <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.4s">
                                        <div className="dlab-card blog-grid overlay-post large">
                                            <MediaBlog postImage={post1} />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-12 col-xl-6 col-sm-12">
                                        <div className="row sp4">
                                            <div className="col-lg-4 col-xl-6 col-md-4 col-sm-6 wow fadeInDown" data-wow-duration="2s" data-wow-delay="0.4s">
                                                <div className="dlab-card blog-grid overlay-post">
                                                    <MediaBlog postImage={post2} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-xl-6 col-md-4 col-sm-6 wow fadeInDown" data-wow-duration="2s" data-wow-delay="0.4s">
                                                <div className="dlab-card blog-grid overlay-post">
                                                    <MediaBlog postImage={post3} />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-xl-12 col-md-4 col-sm-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
                                                <div className="dlab-card blog-grid overlay-post">
                                                    <MediaBlog postImage={post4} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section> */}
              {/*  Main Slider */}
              <section className="content-inner">
                <div className="container">
                  <div className="row">
                    {articleList && articleList.map((data, index) => (
                      <div className="col-md-6 col-lg-4 col-sm-6 mb-5" key={index}>
                        <div className="card card-custom">
                          <img style={{ marginBottom: "85px" }} src={imageLink + 'Subproject/Gallery/large/' + data.large_image} alt="Card img: 2" className="card-image" />
                          <div className="card-custom-body">
                            <div className="dlab-meta">
                              <ul>
                                <li className="post-date"><i className="las la-calendar-alt"></i><span className="text-dark font-weight-bold">{new Date(data.insert_date_time).toLocaleDateString()}</span></li>
                                {
                                  data.author_name ?
                                    <li className="post-user"><i className="las la-user"></i><span className="text-dark font-weight-bold">By {data.author_name}</span></li>
                                    : ""
                                }
                              </ul>
                            </div>
                            <h3 className="dlab-title">
                              <Link>{data.Title}</Link>
                            </h3>
                          </div>
                          <div className="card-overlay">
                            <div className="card-overlay-inner  text-center">
                              <h5 className="custom-font-color font-weight-bold">{data.Title}</h5>
                              <p className="text-center">
                                <div dangerouslySetInnerHTML={{ __html: data.Short_description }}>

                                </div>
                                {/* {data && data.Short_description.substring(0, 150)}… */}
                              </p>
                              <Link to={`/article-details/${data.id}`}><button className="btn btn-primary w-75 mt-3">Read More</button></Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <nav aria-label="Page navigation example">
                                        <Pagination />
                                    </nav> */}
                </div>
              </section>
            </section>
            <div>
              <img src={bgimg} className="bg-view" alt="" />
            </div>
          </div>
        </div>
        <Footer2 />
      </Fragment>
    )
  }
}
export { Pagination };
export default Article;